// src/App.js
import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import HamburgerMenu from './components/HamburgerMenu';
import './components/HamburgerMenu.css';
import Banner from './components/Banner';


function App() {
  return (
    <BrowserRouter>
      <HamburgerMenu />
      <div className='app'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        {/* Voeg meer routes toe zoals nodig */}
      </Routes>
      </div>
      <Banner />
    </BrowserRouter>
      
  );
}

export default App;
