// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAu2NEeKscMMboZ3gLWizYuA7xoBa9Il1U",
  authDomain: "snelontruimingen-d525b.firebaseapp.com",
  projectId: "snelontruimingen-d525b",
  storageBucket: "snelontruimingen-d525b.appspot.com",
  messagingSenderId: "1023259232766",
  appId: "1:1023259232766:web:e56c8b0e4aee11c5c558af",
  measurementId: "G-8859W99G71"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialiseer Firestore
const database = getFirestore(app);

export { database };