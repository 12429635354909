// src/Home.js
import React from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import cleaning from './images/cleaning.png';
import movingTruck from './images/movingtruck.png';
import team from './images/team.png';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  cssEase: "linear"
};

const Home = () => {
    const navigate = useNavigate();
    const navigateToContact = () => {
      navigate('/contact');
    }
  return (
    <div className="home">
      {/* <header className="header">
        <h1 className="title">SnelOntruimingen.nl</h1>
        <p>Professionele ontruimingsdiensten voor al uw behoeften.</p>
      </header> */}

     
      

<div className='background-container'>
    <section className="contact">
        <h2>Contact</h2>
        <p>
        Neem vrijblijvend en eenvoudig contact met ons op om te zien hoe we u van dienst kunnen zijn. 
        </p>
        {/* <Link to="/contact">Ga naar Contactpagina</Link> */}
        <button onClick={navigateToContact}>Ga naar ContactPagina</button>
        
    </section>
    </div>
    <section className="about-us">
        <h2>Over Ons</h2>
        <p>
        Zoekt u een betrouwbare partner voor het professioneel ontruimen van uw woning? 
        Ontdek onze service, waar betrouwbaarheid en efficiëntie voorop staan. 
        Ons ervaren team, gewapend met de juiste middelen, garandeert een zorgvuldige en snelle woningontruiming. 
        Wij werken samen met deskundige partners om elk aspect van het ontruimingsproces te dekken, waardoor u volledig ontzorgd wordt. 
        Neem gerust contact met ons op voor een vrijblijvend gesprek en ontdek hoe wij u specifiek kunnen ondersteunen in uw situatie.
        </p>
      </section>

      <div className='image-container'>
            <div className='image-text'>
                <img src={cleaning} alt="Omschrijving afbeelding 1" />
                <p><strong>Zorgeloze Ontruiming:</strong> Laat het werk over aan ons deskundige team. Wij zorgen voor een efficiënte en zorgvuldige ontruiming.</p>
            </div>
            <div className='image-text'>
                <img src={team} alt="Sterk team"/>
                <p><strong>Deskundig Team:</strong><br /> Ons ervaren team begrijpt de complexiteit van woningontruiming en staat klaar om u te begeleiden door het gehele proces.</p>
            </div>
            <div className='image-text'>
                <img src={movingTruck} alt="moving truck" />
                <p><strong>Persoonlijke Aanpak:</strong><br/> Geen twee situaties zijn hetzelfde. Daarom bieden wij een persoonlijke aanpak, afgestemd op uw specifieke behoeften en wensen.</p>
            </div>
        </div>

      <section className='services'>
        <h2>Services</h2>
        <p>
Wij staan zeven dagen per week, 24 uur per dag paraat om door heel Nederland woningontruimingen te verzorgen. Ons team is gespecialiseerd in het snel en respectvol leeghalen van woningen, ongeacht de grootte of locatie. Wij begrijpen dat een woningontruiming vaak komt in tijden van verandering of verlies, en daarom behandelen wij elke situatie met de grootste zorg en discretie.
</p> 
<p>
Onze offertes worden opgesteld op basis van beknopte en eenvoudige criteria, zodat u precies weet waar u aan toe bent zonder verborgen kosten. U kunt op ons rekenen voor een vakkundige en efficiënte service, waarbij wij snel en professioneel inspelen op uw behoeften. Ons team werkt nauwkeurig en zorgt ervoor dat elke ruimte grondig wordt schoongemaakt en vrijgemaakt van overtollige spullen.
</p>
<p>
Naast de standaard ontruiming, zorgen wij ook dat uw woning verkoopklaar wordt opgeleverd. Dit omvat niet alleen het verwijderen van meubels en andere inboedel, maar ook het uitvoeren van kleine reparaties, schilderwerk en het grondig reinigen van de woning. Ons doel is om uw woning in een staat te brengen waarin deze direct aantrekkelijk is voor potentiële kopers.
</p>
<p>
Tevens bieden wij aanvullende diensten zoals het opslaan van waardevolle spullen, het veilig afvoeren van afval en het doneren van bruikbare items aan lokale liefdadigheidsinstellingen. We streven ernaar om milieuvriendelijk te werken door herbruikbare materialen en voorwerpen een tweede leven te geven.
</p>
<p>
Met onze uitgebreide ervaring in woningontruimingen, zijn wij uw betrouwbare partner voor een zorgeloze en efficiënte afhandeling van uw woningontruiming. Neem vandaag nog contact met ons op voor een vrijblijvende offerte en laat ons u helpen met een soepele en professionele ontruiming.
</p>
        </section>

        <section className='reviews'>
        <h2>Klantbeoordelingen</h2>
        
          {/* Review 1 */}
          <Slider {...settings}>
            <div className="review">
              <div className='review-rating'>
                <span>★★★★★</span>
              </div>
              <p className="review-text">"SnelOntruimingen.nl heeft fantastisch werk geleverd. Ze waren professioneel, zorgvuldig en respectvol. Ik zou ze zonder twijfel aanbevelen!"</p>
              <p className="review-author">- Janine de Vries</p>
            </div>
          
          
          {/* Review 2 */}
          
            <div className="review">
              <div className='review-rating'>
                <span>★★★★★</span>
              </div>
              <p className="review-text">"Efficiënte service en een vriendelijk team. Het hele proces verliep soepel en zonder stress. Bedankt!"</p>
              <p className="review-author">- Pieter Bakker</p>
            </div>
          </Slider>

          
        
      </section>
      
    </div>
  );
};
export default Home;
