// Banner.js
import React from 'react';
import './Banner.css'; // Importeer je CSS-bestand voor styling

function Banner() {
    return (
        <div className="banner">
            <p>E-mail: info@snelontruiming.com</p>
            <p>KVK: 83921362 </p>
            <p>Snel Ontruimingen Netjes Aanbieden - Alle rechten voorbehouden</p>
        </div>
    );
}

export default Banner;
