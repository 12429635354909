import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './Contact.css';
import { database } from './firebase'; // Importeer de database configuratie van Firebase
import { collection, addDoc } from 'firebase/firestore'; // Importeer de benodigde Firestore functies

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    country: 'NL', // Standaard landcode voor Nederland
    message: '',
  });

  const [submitMessage, setSubmitMessage] = useState(''); // Nieuwe state voor gebruikersfeedback

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(database, 'contactFormulieren'), formData);
      setSubmitMessage('Formulier is succesvol ingediend. Bedankt!');
      setFormData({ name: '', email: '', phone: '', country: 'NL', message: '' }); // Reset het formulier
    } catch (error) {
      console.error('Fout bij het indienen van het formulier:', error);
      setSubmitMessage('Er is een fout opgetreden bij het indienen van het formulier.');
    }
  };

  return (
    <div className="contact">
      <h2>Contactformulier</h2>
      {submitMessage && <p>{submitMessage}</p>} {/* Toon de gebruikersfeedback */}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label>
            Naam:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Telefoonnummer:
            <PhoneInput
              international
              defaultCountry="NL"
              value={formData.phone}
              onChange={(value) => setFormData({...formData, phone: value})}
              required
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            E-mailadres:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-row">
          <label>
            Uw bericht:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </label>
        </div>
        <div className="form-row">
          <button type="submit">Indienen</button>
        </div>
      </form>
    </div>
  );
};

export default Contact;