// src/components/HamburgerMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
  return (
    <div className="menu">
      <div className="menu-links">
        <Link to="/">Home</Link>
        {/* Voeg eventueel meer links toe aan de linkerkant */}
      </div>
      <div className="menu-logo">
        {/* Je logo hier (bijv. een afbeelding of tekst) */}
        {/* <img src="/path-to-your-logo.png" alt="Logo" /> */}
        <div className="home">
      <header className="header">
        <Link to="/"><h1 className="title">SnelNetjesOntruiming.nl</h1></Link>
        
        <p>Professionele ontruimingsdiensten voor al uw behoeften.</p>
      </header>
      </div>
      </div>
      <div className="menu-links">
        <Link to="/contact">Contact</Link>
        {/* Voeg eventueel meer links toe aan de rechterkant */}
      </div>
    </div>
  );
};

export default HamburgerMenu;
